.login__page {
    /* height: 100vh; */
    font-family: "Montserrat", sans-serif;
    position: relative;
    /* background: #fcfcfd; */
    /* margin: -8px;
    overflo\w:; */
  }
  
  .login {
    /* height: 100vh; */
    font-family: "Montserrat", sans-serif;
    position: relative;
    background: #fff;
  }
  
  .login__header {
    min-height: 80px;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #d0e2e2;
  }
  
  .login__header__bar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 95%;
  }
  
  .login__header__logo {
    width: 175px;
  }
  
  .login__header__bar ul {
    display: inline-flex;
    gap: 2rem;
    /* margin: 0; */
    font-size: 1.125rem;
    font-weight: 500;
    color: #5f6368;
    list-style-type: none;
  }
  .login__header__bar ul li a {
    color: #5f6368;
    text-decoration: none;
  }
  .login__header__bar ul li a:hover {
    color: #2b2b2c;
  }
  
  .login__grid__col--form {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .login__grid__heading {
    font-size: 42px;
    letter-spacing: -0.5px;
    line-height: 56px;
    font-weight: 500;
    /* margin-bottom: 1rem; */
    color: #1a1f36;
  }
  
  .login__grid__subheading {
    font-size: 36px;
    font-weight: 600;
    letter-spacing: initial;
    line-height: 28px;
    color: #4f566b;
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .login__form {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .login__inputField {
    width: 270px !important;
  }
  
  .login_input {
    height: 70px;
  }
  
  .btnWrapper {
    padding-top: 15px;
  }
  
  .login__submitBtn {
    width: 100% !important;
    color: white !important;
    border: 0;
    cursor: pointer;
    height: 45px;
    padding: 10px 13px;
    font-size: 16px;
    min-width: 310px;
    background: #088b8c;
    min-height: 30px;
    transition: 0.3s ease all;
    font-weight: bold;
    border-radius: 4px;
    box-shadow: none !important;
    /* margin-top: 20px; */
  }
  
  .login__submitBtn:hover {
    background: #10a8a9;
  }
  
  /* @media (max-width: 1440px) {
    .login__main {
      max-width: 1240px;
    }
  }
  
  @media (max-width: 1024px) {
    .login__main {
      max-width: 800px;
    }
    .login__heroImg {
      left: -40px;
    }
  }
  
  @media (max-width: 768px) {
    .login__grid {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 3rem;
    }
    .login__grid__col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  
    .login__section {
      /* margin: 40px 72px; */
  /* }
    .login__header__bar ul li a {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 425px) {
    .login__header__bar {
      flex-direction: column;
      padding: 1rem 0rem;
    }
    .login__section {
      /* margin: 20px; */
  /* }
  }  */
  
  .MuiFormHelperText-contained {
    margin-left: 0px !important;
  }
  
  .helper_text_wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding-top: 1rem;
  }
  
  .helper_text_large {
    color: #697386;
    font-size: 14px;
    padding-bottom: 0.5rem;
  }
  
  .helper_text_small {
    color: #a3acb9;
    font-size: 12px;
    padding-bottom: 4rem;
  }
  
  /* .footer_container {
    display: flex;
    justify-content: space-evenly;
    gap: 2rem;
    border-top: 2px solid #f2f2f2;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: #f3f8f9;
  } */
  
  .footer_container {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    justify-content: space-evenly;
    gap: 2rem;
    border-top: 2px solid #f2f2f2;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: #f3f8f9;
  }
  
  .box_heading {
    color: #4f566b;
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 0.8rem;
  }
  
  .list_item {
    color: #8792a2;
    font-size: 16px;
    line-height: 24px;
  }
  .login__main {
    position: relative;
    top: 130px;
  }
  