.dataTable_head {
  background-color: #f2fbf9;
  border-top: 1px solid #d7d0d9;
}

.data_table_filter_container {
  padding: 0.5rem 1.5rem 0.5rem 2rem;
  display: flex;
  justify-content: space-between;
}

.demographics_table_pagination .MuiTablePagination-toolbar {
  padding: 0 !important;
}

.demographics_table_pagination .MuiTablePagination-selectRoot {
  margin-left: 2px !important;
  margin-right: 8px !important;
}

.demographics_table_pagination .MuiTablePagination-actions {
  margin-left: 10px !important;
}

.demographics_table_pagination .MuiIconButton-root {
  padding: 12px 0 !important;
}

.demographics_table_pagination .MuiTableCell-root {
  border: none;
}

.demographics_table_pagination .MuiTablePagination-select {
  font-weight: 600;
}

.demographics_table_pagination .MuiTypography-body2 {
  font-weight: 600;
}

.data_table_cell.MuiTableCell-root {
  padding: 4px 10px !important;
}
.data_table_cell_checkbox.MuiTableCell-root {
  width: 5px;
  padding: 0 8px !important;
}
.data_table_cell.dueDate {
  width: 150px;
}
.owner_dropdown.MuiInput-formControl {
  margin: 0;
}

