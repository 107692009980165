.demographics_tab_form {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.demographics_tab_form_border {
  border-radius: 8px;
  padding: 2rem;
  background-color: #fff;
}
.demographics_heading {
  margin-bottom: 1rem !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 500;
}
.form_buttons {
  display: flex;
  margin-top: 10px;
  gap: 10px;
  align-items: center;
}
