.uploadList_container {
  background-color: #fff;
  /* width: 100%; */
  padding: 2rem;
  border-radius: 8px;
  margin: 0 10px;
}
.upload_heading {
  /* margin-bottom: 2rem !important; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 500;
}
