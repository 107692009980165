/* ********* Global Color styling for the full app ********* */
:root {
    --main-bg-color: #f3f2ef;
    --Grey1: #333333;
    --Grey2: #4f4f4f;
    --Grey3: #828282;
    --Grey4: #bdbdbd;
    --Grey5: #e0e0e0;
    --Grey5--hover: #c5c5c5;
    --Grey6: #f2f2f2;
    --Blue: #4a9ef7;
    --Blue-hover: #1f86f4;
    --Container-grey: #f0f0f0;
    --Container-grey-hover: #d1d1cf;
    --Container-text: #707683;
    --Secondary-green: #14a6a6;
    --Secondary-green--hover: #0d7979;
    --Secondary-green--hoverLight: rgba(181, 255, 255, 0.2);
    --Secondary-green--light: #e0f0f2;
    --Secondary-green--dark: #139696;
    --line-grey: #d7e0e9;
  
    --color-grey-50: #f7fafc;
    --color-grey-100: #e3e8ee;
    --color-grey-dark-100: #7a8394;
    --color-grey-200: #c1c9d2;
    --color-grey-300: #a3acb9;
    --color-grey-400: #8792a2;
    --color-grey-500: #697386;
    --color-grey-600: #59617b;
    --color-grey-700: #3c4257;
    --color-grey-800: #2a2f45;
    --color-grey-900: #1a1f36;
  }
  /* ****************************************** */
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  
  /** Custom scrollbar for all 'Overflows Y and X' 
  [Always reuse the className="scrollbarY--custom"] on div element
  if scrollbar is needed*/
  .scrollbarY--custom::-webkit-scrollbar {
    width: 0.4em;
    height: 0.6rem;
    border-radius: 0px 10px 0px 0px;
  }
  
  .scrollbarY--custom::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  }
  
  .scrollbarY--custom::-webkit-scrollbar-thumb {
    background-color: rgb(182, 182, 182);
    border-radius: 10px 20px 10px 10px;
  }
  
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
  
  body {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
  }
  
  .MuiButton-contained {
    box-shadow: none !important;
  }
  
  .MuiTypography-body1,
  .MuiButton-containedPrimary {
    font-family: 'Inter', sans-serif;
  }
  .react-datepicker {
    font-family: 'Inter', sans-serif;
  }
  .MuiTypography-body2,
  .MuiChip-label,
  .MuiTypography-body1 {
    font-family: 'Inter', sans-serif;
    font-size: 1rem !important;
  }
  
  .fc .fc-toolbar-title {
    font-size: 15px !important;
    margin: 0 auto;
    text-align: center;
  }
  
  .fc .fc-timegrid-slot {
    height: 102px !important;
    background: #fff !important;
  }
  
  .switch.btn {
    width: 100%;
  }
  
  button:focus {
    outline: 0px;
  }
  
  :focus {
    outline: 0px;
  }
  
  .MuiFormLabel-asterisk {
    color: red;
  }
  
  .fc-direction-ltr .fc-timegrid-col-events {
    margin: 0 4.5% 0 5px !important;
  }
  
  .fc-timegrid-event {
    border-radius: 15px !important;
  }
  
  .MuiAutocomplete-clearIndicator {
    display: none !important;
  }
  
  .fc-theme-standard .fc-scrollgrid {
    border: 0px solid #ddd !important;
    background: #fff;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 1px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
    border-radius: 15px;
  }
  
  .fc-license-message {
    display: none;
  }
  
  .fc .fc-timegrid-axis-cushion,
  .fc .fc-timegrid-slot-label-cushion {
    padding: 0 20px !important;
  }
  
  .fc .fc-timegrid-col.fc-day-today {
    background: #fff !important;
  }
  
  .fc-col-header-cell.fc-resource {
    padding: 25px;
  }
  
  .calender-section-two .MuiInput-underline:before,
  .calender-section-two .MuiInput-underline:after {
    border-bottom: 0px solid rgba(0, 0, 0, 0) !important;
  }
  
  .fc-col-header-cell.fc-resource .fc-col-header-cell-cushion {
    color: #fff;
    background: #7d89d9;
    padding: 10px 15px !important;
    border-radius: 30px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 1px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 4px 0px rgba(0, 0, 0, 0.12);
  }
  
  .MuiTableCell-body {
    text-align: center;
  }
  
  .react-datepicker__today-button {
    background: #f0f0f0;
    border: 1px solid #aeaeae;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    clear: none;
    width: 100px;
    margin: 0 10px;
  }
  
  .react-datepicker__header {
    background-color: #7d89d9 !important;
    border-radius: 0 !important;
  }
  
  .react-datepicker__current-month {
    color: white !important;
  }
  
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: #7d89d9 !important;
    color: #fff;
  }
  
  .react-datepicker__day-name {
    color: #fff !important;
  }
  
  .react-datepicker__today-button {
    margin-bottom: 10px;
  }
  
  .react-datepicker__input-container {
    display: none !important;
  }
  
  .react-datepicker__triangle {
    display: none !important;
  }
  
  .react-datepicker__navigation--next {
    border-left-color: #fff !important;
  }
  
  .react-datepicker__navigation--previous {
    border-right-color: #fff !important;
  }
  
  .MuiTable-root {
    overflow: 'auto';
  }
  
  .searchPatient .MuiTableCell-body {
    text-align: left !important;
  }
  
  .date-section-one li {
    /* height: 105px !important; */
    height: fit-content !important;
  }
  
  /* .MuiGrid-grid-xs-2 {
    flex-grow: 0;
    max-width: 14.6% !important;
    flex-basis: 14.6% !important;
  }
  .MuiGrid-grid-xs-6 {
    flex-grow: 0;
    max-width: 52% !important;
    flex-basis: 52% !important;
  } */
  .accordion .subheading {
    font-size: 0.8rem !important;
  }
  
  @media all and (max-width: 2200px) and (min-width: 1875px) {
    /* .MuiGrid-grid-xs-2 {
      flex-grow: 0;
      max-width: 12.6% !important;
      flex-basis: 12.6% !important;
    }
  
    .MuiGrid-grid-xs-6 {
      flex-grow: 0;
      max-width: 54% !important;
      flex-basis: 54% !important;
    } */
  }
  .MuiTableCell-head,
  .MuiTableCell-body {
    color: rgba(0, 0, 0, 0.75) !important;
    /* font-size: 1rem !important; */
  }
  
  @media all and (max-width: 2000px) and (min-width: 900px) {
    .calender-section-two .MuiChip-root {
      height: 31px !important;
    }
    .accordion .subheading {
      font-size: 0.7rem !important;
    }
  
    .MuiTypography-body2,
    .MuiChip-label,
    .MuiTypography-body1 {
      font-family: 'Inter', sans-serif;
      font-size: 0.85rem !important;
    }
  
    .ptIcon {
      height: 20px !important;
      width: 20px !important;
      color: #fff;
    }
  
    .section-three .MuiSvgIcon-root {
      padding-right: 6px !important;
    }
  
    .MuiGrid-spacing-xs-2 > .MuiGrid-item {
      padding: 6px !important;
    }
    .accordion .MuiInputBase-input {
      font-size: 0.85rem;
    }
    .accordion .MuiFormLabel-root {
      font-size: 0.85rem;
    }
    .fc .fc-toolbar-title {
      font-size: 0.8rem !important;
    }
    .fc .fc-toolbar.fc-header-toolbar {
      margin-bottom: 0.5em !important;
    }
  
    .fc-col-header-cell.fc-resource .fc-col-header-cell-cushion {
      font-size: 12px !important;
      padding: 5px 12px !important;
    }
    .fc-col-header-cell.fc-resource {
      padding: 11px !important;
    }
    .section-three .MuiCard-root .MuiCard-root {
      margin-bottom: 0px;
    }
    .calender-section-two {
      padding: 7px !important;
    }
  
    .MuiGrid-grid-xs-2 {
      flex-grow: 0;
      max-width: 16.666667% !important;
      flex-basis: 16.666667% !important;
    }
    .MuiGrid-grid-xs-6 {
      flex-grow: 0;
      max-width: 50% !important;
      flex-basis: 50% !important;
    }
  
    .drawerStyle {
      width: 150px !important;
    }
    .date-section-one li {
      height: fit-content !important;
    }
    .MuiTableCell-head,
    .MuiTableCell-body {
      color: rgba(0, 0, 0, 0.75) !important;
      font-size: 0.85rem !important;
    }
  }
  
  .MuiTab-wrapper {
    text-transform: none !important;
    font-size: 0.99rem;
    font-weight: 600;
  }
  .MuiTypography-h5 {
    font-size: 1rem !important;
    font-weight: 500 !important;
  }
  
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense']
    .MuiAutocomplete-input {
    display: none !important;
  }
  
  .MuiPaper-root.MuiAccordion-root.accordionAppt.MuiPaper-elevation1::before {
    background-color: var(--line-grey) !important;
  }
  
  .MuiPaper-root
    .MuiMenu-paper
    .MuiPopover-paper
    .MuiPaper-elevation8
    .MuiPaper-rounded {
    border: 1px solid blue;
  }
  