.claim_history {
  padding: 2rem;
  min-height: 700px !important;
}
.dialog_title {
  margin-bottom: 1.5rem !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 500;
}
.claim_history_btns {
  display: flex;
  justify-content: flex-end;
  height: 30px;
}
.data_table_list_cell.MuiTableCell-root {
  padding: 8px;
  background-color: #f2fbf9;
}
.nodatafound {
  height: 540px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data_table_cell.MuiTableCell-root {
  padding: 8px;
}
.status_dropdown.MuiInput-formControl {
  margin: 0;
}
.data_table_cell.status {
  width: 150px;
}
