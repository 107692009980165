.wrapper {
  display: flex;
}
.main_edit_section {
  width: 50%;
}
.right_tab_section {
  width: 50%;
}
.demographics_container {
  /* display: flex; */
  justify-content: center;
  padding: 0.5rem 0 1rem 0;
  margin: 5px;
}
.button_container {
  padding: 1.5rem 0 0 1rem;
}
.button_group {
  display: flex;
  gap: 10px;
}
.tabButtons {
  color: black !important;
  height: 48px;
  /* width: 90px; */
  white-space: nowrap;
  font-size: 16px !important;
  box-shadow: none;
  color: white !important;
}

.button_active {
  background-color: white !important;
  color: black !important;
  height: 48px;
  /* width: 90px; */
  white-space: nowrap;
  font-size: 16px !important;
  box-shadow: none;
}

.leftTabOpen {
  margin-bottom: 1.2rem;
}

.main_edit_section_button {
  display: flex;
  justify-content: flex-end;
  padding: 1.5rem 0 0 1rem;
  margin-right: 5px;
}
