.call_history_container {
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding: 2rem;
  margin: 0 10px;
  scroll-behavior: smooth;

  max-height: 80vh; /* Set the maximum height to the viewport height */
  overflow-y: auto; /* Add a scrollbar if the content exceeds the maximum height */
}

.call_history_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 500;
}
.nots_form {
  width: 100%;
  padding-top: 1rem;
  margin-left: -12px;
}
.call_history_table_cell.MuiTableCell-root {
  padding: 16px 0;
  /* background-color: red; */
  width: 100vw;
}
.notesTabForm_container {
  display: flex;
  gap: 1rem;
  padding-bottom: 10px;
  justify-content: center;
  align-items: center;
}
.notesTab__input {
  width: 80%;
}
.notesTab__btnContainer {
  width: 15%;
  display: flex;
  gap: 10px;
  align-items: center;
}
.notes_error {
  color: red;
  font-size: 14px;
}
