.insurance_list_container {
  background-color: #fff;
  width: 100%;
  padding: 2rem;
  border-radius: 8px;
  /* margin: 0 10px; */
}
.insurance_heading {
  margin-bottom: 1rem !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 500;
}
.ins_field_set {
  display: flex;
  width: 120%;
  flex-direction: row;
  /* width: 90%; */
}
.insuranceName {
  /* border: 1px solid red !important; */
  /* padding-right: 5px !important; */
  width: 130px !important;
  .input_field_content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.input_field_view_mode {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  height: 100%;
  /* width: 200px; */
  justify-content: space-between;
  padding-bottom: 6px;
}
.label {
  color: #676767;
  font-size: 12px;
}
.input_field_content_wrap {
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.input_field_content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.edit_btn_policyInfo {
  margin-top: 10px;
}
.insurance_closeIcon {
  cursor: pointer;
  position: relative;
  right: 35px;
  bottom: -19px;
}
.insurance_addicon {
  /* border: 1px solid red; */
  cursor: pointer;
  position: relative;
  bottom: -20px !important;
  right: 75px !important;
}
.edit_btn_group {
  display: flex;
  gap: 10px;
}
.policyList_tabelCell {
  white-space: nowrap;
}
.policyList_tabelCell.MuiTableCell-root {
  padding: 0 10px;
}
