.insurance_tab_container {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.insurace_tab_form {
  border-radius: 8px;
  padding: 1rem 2rem;
  background-color: #fff;
}

.form_heading {
  margin-bottom: 1.5rem !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 500;
}

.form_buttons {
  display: flex;
  margin-top: 10px;
  gap: 10px;
  align-items: center;
}