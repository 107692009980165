.wrapper {
  background-color: #c7c7c738;
  display: flex;
}
.open {
  /* padding-right: 10px; */
  margin-right: 1rem;
  background-color: white;
  padding-bottom: 10px;
  z-index: 3;
  height: 100%;
  border-radius: 8px;
}
.patient_selector_heading_container {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  padding-top: 10px;
  display: flex;
  align-items: center;
  padding-right: 3px;
}
.left_tab_pagination {
  /* padding: ; */
}
.dropdown_header {
  font-size: 18px;
  padding: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  padding-block: 1.2rem;
  font-weight: 500;
}
.dropdown_title {
  /* padding-left: 10px; */
}
.tab_container {
  height: 85vh;
  min-width: 260px;
  overflow-y: hidden !important;
}
.tabRow {
  width: 100%;
  background-color: #d9ebe7;
  /* background-color: red; */
  /* border-radius: 10px; */
  height: 67px !important;
  text-transform: capitalize;
  /* padding-right: 8px; */
  display: flex;
  flex-direction: column;
}
.active_tab {
  width: 100%;
  height: 67px !important;
  /* border-radius: 25px; */
  /* padding-right: 8px; */
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
}
.firstRow {
  font-size: 15px;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding-top: 5px;
  align-items: flex-end;
  width: 95%;
  text-align: left;
  margin-left: 5px;
  white-space: nowrap;
  font-weight: 500;
  padding-left: 8px;
}
.secondRow {
  font-size: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  gap: 0.5rem;
  font-weight: 500;
  width: 95%;
  padding-bottom: 5px;
  margin-left: 5px;
  text-align: left;
  padding-left: 8px;
  color: #759ca4;
}
.mainContainer {
  display: flex;
  width: 100%;
}

@media (max-width: 1872px) {
  f .open {
    margin-right: 1rem;
    background-color: white;
    padding-bottom: 10px;
    z-index: 3;
    height: 100%;
    border-radius: 8px;
  }
  .dropdown_header {
    font-size: 14px;
    padding: 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
}
.close_left_tab {
  background-color: #fff;
  height: 100%;
  margin-right: 1rem;
  border-radius: 0 8px 8px 0;
}
