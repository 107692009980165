.upload_button {
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
}
.file_uploader_heading{
    /* margin-bottom: 1rem !important; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.25rem;
    font-weight: 500;
}